<template>
    <ion-page data-pageid="home">
        <ion-content :scroll-events="false">
            <ChinhSuaThongTinCaNhanVue
                TieuDe="Số điện thoại"
                :model="model.SoDienThoai"
                keyChinhSua="soDienThoai"
            >
                <template #content>
                    <DxValidationGroup ref="formValidation">
                        <span class="font-12">Số điện thoại (*)</span>
                        <DxTextBox
                            class="mb-3"
                            v-model="model.SoDienThoai"
                            validationMessageMode="always"
                            ref="refTab1"
                            styling-mode="underlined"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Số điện thoại không được bỏ trống!"
                                />
                                <DxStringLengthRule
                                    :max="13"
                                    message="Số điện thoại không được vượt quá 13 ký tự!"
                                />
                                <DxNumericRule
                                    message="Số điện thoại phải là số!"
                                /> </DxValidator
                        ></DxTextBox>
                    </DxValidationGroup>
                </template>
            </ChinhSuaThongTinCaNhanVue>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    DxNumericRule,
    DxRequiredRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import ChinhSuaThongTinCaNhanVue from "../../../src/components/_Common/ChinhSuaThongTinCaNhan";
import { DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { IonContent, IonPage } from "@ionic/vue";
export default {
    layout: "application",
    components: {
        ChinhSuaThongTinCaNhanVue,
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxNumericRule,
        IonContent,
        IonPage,
    },
    props: {},
    data() {
        return {
            model: { SoDienThoai: "" },
        };
    },
    methods: {},
    created() {},
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }
        this.model.SoDienThoai = this.$route.query.data;
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>